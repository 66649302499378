export function loadFancybox() {
    // http://fancyapps.com/fancybox/3/docs/
    import(/* webpackChunkName: "fancybox" */'@plugins/fancybox/jquery.fancybox.min.css');
    return import(/* webpackChunkName: "fancybox" */'@plugins/fancybox/jquery.fancybox.min');
}

export function initFullScreenImages($selector) {
    $selector
        .fancybox({
            transitionIn: 'elastic',
            transitionOut: 'elastic',
            speedIn: 600,
            speedOut: 200,
            overlayShow: false,
        });
}
