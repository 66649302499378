export function debounce(func, threshold, execAsap) {
    let timeout;
    return function debounced() {
        const obj = this;
        const args = arguments;

        function delayed() {
            if (!execAsap) func.apply(obj, args);
            timeout = null;
        }

        if (timeout) {
            clearTimeout(timeout);
        } else if (execAsap) func.apply(obj, args);
        timeout = setTimeout(delayed, threshold || 300);
    };
}

export function throttle(func, ms) {
    let isThrottled = false;
    let savedArgs;
    let savedThis;

    function wrapper() {
        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return;
        }
        func.apply(this, arguments);
        isThrottled = true;
        setTimeout(() => {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

export function sendRequest(type, url, data, callBack, options) {
    const defaultOptions = {
        type,
        url,
        data,
        dataType: 'json',
        success(response, textStatus, jqXHR) {
            if (callBack) {
                callBack.call(self, response);
            }
        },
        error(jqXHR, textStatus, errorThrown) {
            if (callBack) {
                callBack.call(self, jqXHR.responseJSON);
            }
        },
    };
    return $.ajax({...defaultOptions, ...options});
}

export function serializeObject(obj) {
    const str = [];
    for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
        }
    }
    return str.join('&');
}
