/**
 * Оболочка над ziggy (https://github.com/tighten/ziggy),
 * позволяет использовать именованные роуты laravel в js,
 * сами роуты экспортируются в html страницы (@routes)
 */
import * as ziggy from 'ziggy-js';

export function route(name, data = null) {
    return ziggy(name, data);
}

export const routes = {
    vuzRyadom: {
        regions: (() => {
            return route('vuzRyadom.regions', {});
        }),
        interests: (() => {
            return route('vuzRyadom.interests', {});
        }),
        naprs: ((interestId) => {
            return route('vuzRyadom.naprs', {interestId});
        }),
        interestTips: ((regionKod) => {
            return route('vuzRyadom.interestTips', {regionKod});
        }),
        naprTips: ((regionKod, interestId) => {
            return route('vuzRyadom.naprTips', {regionKod, interestId});
        }),
        updateStatistics: ((regionKod, interestId, naprId) => {
            return route('vuzRyadom.statistic', {regionKod, interestId, naprId});
        }),
        showResult: ((hash) => {
            return route('vuzRyadom.getResultPage', {hash});
        }),
    },
    public: {
        login: (() => {
            return route('login', {});
        }),
        loginAuth: ((role) => {
            return route('auth.social.login', {role});
        }),
        accreditations: {
            getOrganizationsList: (() => {
                return route('accreditations.getOrganizationsList', {});
            }),
            getAgenciesList: (() => {
                return route('accreditations.getAgenciesList', {});
            }),
        },
        rankings: {
            pnar: (() => {
                return route('rankings.national.subject.aggregated', {});
            }),
        },
        organizations: {
            getOrganizationsList: (() => {
                return route('organizations.getOrganizationsList', {});
            }),
            // competitions: {
            //     getRegionsList: (() => {
            //         return route('organizations.competitions.getRegionsList', {});
            //     }),
            //     getLocationsList: (() => {
            //         return route('organizations.competitions.getLocationsList', {});
            //     }),
            // },
            commissions: {
                sendApplicant: (() => {
                    return route('organizations.commissionApplicant', {});
                }),
            }
        },
        consulting: {
            demo: {
                nar: ((hash) => {
                    return route('consulting.demo.nar', {hash});
                }),
            }
        },
        plans: {
            sendApplicant: () => {
                return route('services.applicant', {});
            }
        }
    },
    admin: {
        organization: {
            login: (organizationId) => {
                return route('admin.organizations.login', {organizationId});
            },
        },
        userManager: {
            changeKindUser: ((userId) => {
                return route('lk.userManager.remove', {userId});
            }),
            save: ((userId) => {
                return route('admin.userManager.saveJson', {userId});
            }),
            edit: ((userId) => {
                return route('admin.userManager.edit', {userId});
            }),
        },
        media: {
            save: ((mediaId) => {
                return route('admin.media.saveJson', {mediaId});
            }),
            edit: ((mediaId) => {
                return route('admin.media.edit', {mediaId});
            }),
            remove: ((mediaId) => {
                return route('admin.media.delete', {mediaId});
            })
        },
        newsletter: {
            uploadImage: ((token) => {
                return route('admin.newsletter.uploadImage', {_token: token})
            }),
            sendMail: (() => {
                return route('admin.newsletter.sendMail', {})
            })
        },
        accreditationProgram: {
            index: (() => {
                return route('admin.accreditationProgram', {});
            }),
            save: ((accreditationProgramId) => {
                return route('admin.accreditationProgram.saveJson', {accreditationProgramId});
            }),
            edit: ((accreditationProgramId) => {
                return route('admin.accreditationProgram.edit', {accreditationProgramId});
            }),
            remove: ((accreditationProgramId) => {
                return route('admin.accreditationProgram.delete', {accreditationProgramId});
            }),
            changeStatus: ((accreditationId) => {
                return route('admin.accreditationProgram.changeStatus', {accreditationId});
            }),
            deleteFile: ((href) => {
                return route('admin.accreditationProgram.deleteFile', {href});
            }),
        }
    },
    lk: {
        profile: {
            index: (() => {
                return route('lk.profile', {});
            }),
            saveProfile: (() => {
                return route('lk.saveProfile', {});
            }),
        },
        abitur: {
            favoriteOrganizations: {
                save: (() => {
                    return route('lk.abitur.favoriteOrganizations.save', {});
                }),
                addOrDelete: (() => {
                    return route('lk.abitur.favoriteOrganizations.addOrDelete', {});
                }),
            },
        },
        captcha: {
            reloadCaptcha: () => {
                return route('reloadCaptcha', {});
            },
        },
        organization: {
            details: () => {
                return route('lk.organization.details', {});
            },
            commonData: {
                save: () => {
                    return route('lk.organization.commonData.save', {});
                },
            },
            objects: {
                save: () => {
                    return route('lk.organization.objects.save', {});
                },
            },
            grants: {
                save: () => {
                    return route('lk.organization.grants.save', {});
                },
            },
            viewSettings: {
                save: () => {
                    return route('lk.organization.viewSettings.save', {});
                },
            },
            consulting: {
                createModal: () => {
                    return route('lk.consulting.createModal', {});
                },
                createCompetitors: () => {
                    return route('lk.consulting.createCompetitors', {});
                },
                getVuzsList: () => {
                    return route('lk.consulting.getVuzsList', {});
                },
                getNarConsultingDocument: () => {
                    return route('lk.consulting.getNarDocument', {});
                },
                validateCountCompetitors: () => {
                    return route('lk.consulting.validateCountCompetitors', {});
                },
                getPnarConsultingDocument: () => {
                    return route('lk.consulting.getPnarDocument', {});
                },
            },
        },
        userManager: {
            remove: ((userId) => {
                return route('lk.userManager.remove', {userId});
            }),
            save: (() => {
                return route('lk.userManager.save', {});
            }),
            edit: ((userId) => {
                return route('lk.userManager.edit', {userId});
            }),
        },
        naprs: {
            remove: ((naprId) => {
                return route('lk.organization.naprs.remove', {naprId});
            }),
            save: (() => {
                return route('lk.organization.naprs.save', {});
            }),
            edit: ((naprId) => {
                return route('lk.organization.naprs.edit', {naprId});
            }),
        },
        programs: {
            remove: ((naprId, programId) => {
                return route('lk.organization.naprs.programs.remove', {naprId, programId});
            }),
            save: ((naprId) => {
                return route('lk.organization.naprs.programs.save', {naprId});
            }),
            edit: ((naprId, programId) => {
                return route('lk.organization.naprs.programs.edit', {naprId, programId});
            }),
        },
        campaigns: {
            remove: ((campaignId) => {
                return route('lk.organization.campaigns.remove', {campaignId});
            }),
            save: (() => {
                return route('lk.organization.campaigns.save', {});
            }),
            edit: ((campaignId) => {
                return route('lk.organization.campaigns.edit', {campaignId});
            }),
        },
        competitionsGroups: {
            remove: ((campaignId, competitionsGroupsId) => {
                return route('lk.organization.campaigns.competitionsGroups.remove', {campaignId, competitionsGroupsId});
            }),
            save: ((campaignId) => {
                return route('lk.organization.campaigns.competitionsGroups.save', {campaignId});
            }),
            edit: ((campaignId, competitionsGroupsId) => {
                return route('lk.organization.campaigns.competitionsGroups.edit', {campaignId, competitionsGroupsId});
            }),
        },
        competitionsGroupsObjects: {
            getCompetitionsGroupsObjectsList: ((campaignId, competitionsGroupsId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsObjects.getCompetitionsGroupsObjectsList', {
                    campaignId,
                    competitionsGroupsId,
                });
            }),
            createModal: ((campaignId, competitionsGroupsId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsObjects.createModal', {
                    campaignId,
                    competitionsGroupsId,
                });
            }),
            saveJson: ((campaignId, competitionsGroupsId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsObjects.saveJson', {
                    campaignId,
                    competitionsGroupsId,
                });
            }),
            remove: ((campaignId, competitionsGroupsId, competitionsGroupsObjectsId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsObjects.remove', {
                    campaignId,
                    competitionsGroupsId,
                    competitionsGroupsObjectsId,
                });
            }),
        },
        competitionsGroupsWrapper: {
            getCompetitionsGroupsWrapperList: ((campaignId, competitionsGroupsId) => {
                return route(
                    'lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.getCompetitionsGroupsWrapperList',
                    {
                        campaignId,
                        competitionsGroupsId,
                    },
                );
            }),
            saveJson: ((campaignId, competitionsGroupsId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.saveJson', {
                    campaignId,
                    competitionsGroupsId,
                });
            }),
            remove: ((campaignId, competitionsGroupsId, competitionsGroupsWrapperId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.remove', {
                    campaignId,
                    competitionsGroupsId,
                    competitionsGroupsWrapperId,
                });
            }),
            getCompetitionsList: ((campaignId, competitionsGroupsId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitions.getCompetitionsList', {
                    campaignId,
                    competitionsGroupsId,
                });
            }),
        },
        competitions: {
            createModal: ((campaignId, competitionsGroupsId, competitionsGroupsWrapperId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.competitions.createModal', {
                    campaignId,
                    competitionsGroupsId,
                    competitionsGroupsWrapperId,
                });
            }),
            editModal: ((
                campaignId,
                competitionsGroupsId,
                competitionsGroupsWrapperId,
                competitionsId,
            ) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.competitions.editModal', {
                    campaignId,
                    competitionsGroupsId,
                    competitionsGroupsWrapperId,
                    competitionsId,
                });
            }),
            saveJson: ((campaignId, competitionsGroupsId, competitionsGroupsWrapperId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.competitions.saveJson', {
                    campaignId,
                    competitionsGroupsId,
                    competitionsGroupsWrapperId,
                });
            }),
            remove: ((
                campaignId,
                competitionsGroupsId,
                competitionsGroupsWrapperId,
                competitionsId,
            ) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.competitions.remove', {
                    campaignId,
                    competitionsGroupsId,
                    competitionsGroupsWrapperId,
                    competitionsId,
                });
            }),
        },
        exams: {
            createModal: ((campaignId, competitionsGroupsId, competitionsGroupsWrapperId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.examsPriorities.createModal', {
                    campaignId,
                    competitionsGroupsId,
                    competitionsGroupsWrapperId,
                });
            }),
            addExamToModal: (() => {
                return route('lk.exams.addExamToModal', {});
            }),
            getExamsDataList: ((campaignId, competitionsGroupsId, competitionsGroupsWrapperId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.examsPriorities.getExamsDataList', {
                    campaignId,
                    competitionsGroupsId,
                    competitionsGroupsWrapperId,
                });
            }),
            editModal: ((
                campaignId,
                competitionsGroupsId,
                competitionsGroupsWrapperId,
                examsPrioritiesId,
            ) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.examsPriorities.editModal', {
                    campaignId,
                    competitionsGroupsId,
                    competitionsGroupsWrapperId,
                    examsPrioritiesId,
                });
            }),
            saveJson: ((campaignId, competitionsGroupsId, competitionsGroupsWrapperId) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.examsPriorities.saveJson', {
                    campaignId,
                    competitionsGroupsId,
                    competitionsGroupsWrapperId,
                });
            }),
            remove: ((
                campaignId,
                competitionsGroupsId,
                competitionsGroupsWrapperId,
                examsPrioritiesId,
            ) => {
                return route('lk.organization.campaigns.competitionsGroups.competitionsGroupsWrapper.examsPriorities.remove', {
                    campaignId,
                    competitionsGroupsId,
                    competitionsGroupsWrapperId,
                    examsPrioritiesId,
                });
            }),
        },
        subjects: {
            remove: ((subjectId) => {
                return route('lk.organization.subjects.remove', {subjectId});
            }),
            save: (() => {
                return route('lk.organization.subjects.save', {});
            }),
            edit: ((subjectId) => {
                return route('lk.organization.subjects.edit', {subjectId});
            }),
        },
        commissions: {
            remove: ((commissionId) => {
                return route('lk.organization.commissions.remove', {commissionId});
            }),
            save: (() => {
                return route('lk.organization.commissions.save', {});
            }),
            edit: ((commissionId) => {
                return route('lk.organization.commissions.edit', {commissionId});
            }),
        },
        accreditationProgramOrganization: {
            index: (() => {
                return route('lk.organization.accreditationProgramOrganizations', {});
            }),
            save: ((accredProgramVuzId) => {
                return route('lk.organization.accreditationProgramOrganizations.save', {accredProgramVuzId});
            }),
            edit: ((accredProgramVuzId) => {
                return route('lk.organization.accreditationProgramOrganizations.edit', {accredProgramVuzId});
            }),
            remove: ((accredProgramVuzId) => {
                return route('lk.organization.accreditationProgramOrganizations.remove', {accredProgramVuzId});
            }),
            setModerationStatus: ((accredProgramVuzId) => {
                return route('lk.organization.accreditationProgramOrganizations.setModerationStatus', {accredProgramVuzId});
            }),
            deleteFile: ((href) => {
                return route('lk.organization.accreditationProgramOrganizations.deleteFile', {href});
            }),
        },
        faculties: {
            index: (() => {
                return route('lk.organization.faculties', {});
            }),
            edit: (() => {
                return route('lk.organization.faculties.edit', {});
            }),
            save: ((facultiesId) => {
                return route('lk.organization.faculties.save', {facultiesId});
            }),
            remove: ((facultiesId) => {
                return route('lk.organization.faculties.remove', {facultiesId});
            }),
        }
    },
};
