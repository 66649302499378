export default function moveAndHighlight($target, highlightClass = '', additionalOffset = 0) {
    const $header = $('header.header');
    const menuHeight = $header.height();
    const timeouts = {
        animateScroll: 400,
        removeHighlightClass: 5000,
    };
    if (highlightClass) {
        $target.addClass(highlightClass);
        setTimeout(() => { return $target.removeClass(highlightClass); }, timeouts.removeHighlightClass);
    }
    $([document.documentElement, document.body])
        .animate({
            scrollTop: $target.offset().top - (menuHeight * 3) - (additionalOffset * 2),
        }, timeouts.animateScroll);
}
