import {sendRequest} from '@js/utils';

export default function initNewsletterForm() {
    $('body')
        .on('click', '.newsletter-subscribe-btn', function () {
            const $form = $(this)
                .closest('form');
            const data = $form.serialize();
            const $errorContainer = $form.find('.newsletter-input-error');
            const $formBodyContainer = $form.find('.newsletter-input-text-wrapper');

            function handler(response) {
                if (response) {
                    if (typeof response.success !== 'undefined' && response.success) {
                        $errorContainer.hide();
                        $formBodyContainer.html($('<p>', {class: 'alert'})
                            .text(response.message));
                    } else if (typeof response.errors !== 'undefined') {
                        $errorContainer.html('');
                        const messages = [];
                        for (const field in response.errors) {
                            for (const index in response.errors[field]) {
                                messages.push(response.errors[field][index]);
                            }
                        }
                        if (messages) {
                            $errorContainer.append(messages.join('<br>'));
                            $errorContainer.show();
                        }
                    }
                }
            }

            sendRequest('post', '/subscribe/newsletters', data, handler);
        });
}
