import {isMobileOrTablet} from '@js/helpers/screen';
import {initSocialButtons, loadJssocials} from '@js/components/socialButtons';
import {initFullScreenImages, loadFancybox} from '@js/components/fullscreenGallery';
import initFixedHeaderMenu from '@js/components/fixedHeaderMenu';
import initFootnotes from '@js/components/footnotes';
import initNewsletterForm from '@js/components/newsletterForm';
import Modal from '@js/components/modal';
import {sendRequest} from '@js/utils';
import initContactForm from '@js/components/feedbackForm';
import Notifier from '@js/components/Notifier';
import {routes} from "@js/helpers/routes";
import '@plugins/jquery.autocomplete/jquery.autocomplete.css';
import '@plugins/jquery.autocomplete/jquery.autocomplete.min';

$(() => {
    const $header = $('header.header');
    initFixedHeaderMenu($header);
    const $shareContainer = $('#share');
    if ($shareContainer.length) {
        loadJssocials().then(() => {
            initSocialButtons($shareContainer);
        });
    }
    initFootnotes();
    const $fullscreenImages = $('.fullscreen-image');
    if (isMobileOrTablet() && $fullscreenImages.length) {
        loadFancybox().then(() => {
            initFullScreenImages($fullscreenImages);
        });
    }
    initNewsletterForm();
    initFeedbackForm();
    if (document.URL.indexOf("#click_approved") >= 0) {
        $('.show-feedback-modal-btn').click();
    }
    reviewToggleTextHandler();
});

function initFeedbackForm() {
    $('body')
        .on('click', '.show-feedback-modal-btn', () => {
            sendRequest('get', '/contacts/getFeedbackModal', {}, (res) => {
                if (res?.success && res?.modalBody) {
                    const modal = new Modal(res.modalBody);
                    const $modal = modal.show();
                    initContactForm($modal);
                } else {
                    (new Notifier()).error(res?.message);
                }
            });
        });
}

const reviewToggleTextHandler = () => {
    $('body')
        .on('click', '#organizationsSending', () => {
            $('#organizationsSearch').submit();
        })
        .on('click, focus', '#organizationsSearch', () => {
            $('.autocomplete-suggestions').width($('#organizationsSearch').width() + 5);
            $('.autocomplete-suggestions').height('400px');
        });

    const $autocompleteInput = $('#organizationsSearch');
    const width = 700;
    const defaultParams = {
        minChars: 2,
        width,
        triggerSelectOnValidInput: false,
    };
    if ($autocompleteInput.length) {
        initAutocompleteOrganizationSearch($autocompleteInput, defaultParams);
    }
};

function initAutocompleteOrganizationSearch($autocompleteInput, params) {
    const location = routes.public.organizations.getOrganizationsList();
    $(window)
        .scroll(() => {
            $autocompleteInput.autocomplete('hide');
        });
    sendRequest('get', location, {}, (res) => {
        if (res?.success) {
            const autocompleteParam = {
                lookup: res.list,
                maxHeight: null,
                width: null,
            };
            $autocompleteInput.autocomplete({...params, ...autocompleteParam});
        }
    });
}
