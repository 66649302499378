export default function initFixedHeaderMenu($menu) {
    const fixedHeadMenuHandler = function () {
        const classes = 'menu_fixed animated fadeInDown';
        const windowTop = $(window)
            .scrollTop() + 1;
        windowTop > 50
            ? $menu.addClass(classes)
            : $menu.removeClass(classes);
    };
    fixedHeadMenuHandler();
    $(window).scroll(fixedHeadMenuHandler);
}
