import {merge} from 'lodash';

export function loadJssocials() {
    // http://js-socials.com/docs/
    import(/* webpackChunkName: "jssocials" */'@scss/components/jssocials-theme.scss');
    return import(/* webpackChunkName: "jssocials" */'jssocials/dist/jssocials.min');
}

export function getDefaultSocialButtonsOptions() {
    return {
        showLabel: false,
        showCount: false,
        shares: ['telegram', /* 'twitter', 'facebook', */'vkontakte', /* 'pinterest', */ 'whatsapp', 'viber', 'email'],
    };
}

export function initSocialButtons($selector, options) {
    const defaultOptions = getDefaultSocialButtonsOptions();
    const socialButtonsOptions = merge(defaultOptions, options);
    $selector.jsSocials(socialButtonsOptions);
}
