import moveAndHighlight from '@js/helpers/navigation';

export default function initFootnotes() {
    $('body')
        .on('click', '.footnote-link', function (e) {
            const $link = $(this);
            const $targetId = $link.data('target');
            const $target = $(`#${$targetId}`);
            if ($target.length) {
                const activeFootnoteClass = 'active-footnote';
                moveAndHighlight($target, activeFootnoteClass);
            }
            e.preventDefault();
        });
}
