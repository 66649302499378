export default class Modal {
    constructor(content) {
        this.content = content;
        this.className = 'dialog';
        this.sizeClass = 'modal-md';
    }

    sizeSm() {
        this.sizeClass = 'modal-sm';
        return this;
    }

    sizeLg() {
        this.sizeClass = 'modal-lg';
        return this;
    }

    show() {
        const {content} = this;
        const modalHtml = `<div class="${this.className} modal fade dlg">
            <div class="modal-dialog ${this.sizeClass}">
                ${content}
            </div>
        </div>`;
        const $modal = $(modalHtml);
        const $body = $('body');
        $modal.modal({backdrop: 'static'})
            .on('shown.bs.modal', () => {
                $modal.find('[data-toggle="tooltip"]').tooltip({
                    container: '.dialog',
                });
            })
            .on('hidden.bs.modal', function () {
                $(this).remove();
            })
            .on('hide.bs.modal', function () {
                $(this).remove();
            });
        $body.append($modal);
        return $modal;
    }
}
