// https://www.npmjs.com/package/toastr
import toastr from 'toastr/build/toastr.min';

export default class Notifier {
    constructor() {
        this.defaultOptions = {
            closeButton: true,
            debug: false,
            newestOnTop: true,
            progressBar: true,
            positionClass: 'toast-top-right',
            preventDuplicates: false,
            onclick: null,
            showDuration: '300',
            hideDuration: '1000',
            timeOut: 5000,
            extendedTimeOut: 2000,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            escapeHtml: false,
        };
        this.options = {};
    }

    preventDuplicates() {
        this.setOptions({preventDuplicates: true});
        return this;
    }

    setOptions(options) {
        this.options = {
            ...this.options,
            ...options,
        };
        return this;
    }

    success(message, title = null, options = {}) {
        const text = message || 'Успешно';
        const alertOptions = {
            ...this.defaultOptions,
            ...this.options,
            ...options,
        };
        toastr.success(text, title, alertOptions);
    }

    warning(message, title = null, options = {}) {
        const alertOptions = {
            ...this.defaultOptions,
            ...this.options,
            ...options,
        };
        toastr.warning(message, title, alertOptions);
    }

    error(message, title = null, options = {}) {
        const text = message || 'Что-то пошло не так. Попробуйте выполнить операцию позже.';
        const alertOptions = {
            ...this.defaultOptions,
            ...this.options,
            ...options,
        };
        toastr.error(text, title, alertOptions);
    }

    clearAll() {
        toastr.clear();
    }
}
